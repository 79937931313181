<template>
   <v-container>
      <crud
         ref="crud"
         api-path="transaction"
         title="Transaction"
         :field="field"
         key-id="id"
         no-create
      >
         <template v-slot:row_act="row">
            <v-btn icon small color="indigo" title="detail" @click="det_id=row.id"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
         </template>
      </crud>
      <v-bottom-sheet :value="!!det_id" v-on:input="det_id=null">
         <v-card tile :loading="load_detail">
            <v-card-actions class="justify-center">
               <template v-if="view.status===2">
                  <v-btn color="red" small dark @click="cancel">batal</v-btn>
                  <v-btn color="blue" small dark @click="process">lanjut</v-btn>
               </template>
               <v-btn color="indigo" small dark @click="print">print</v-btn>
               <v-btn color="red" outlined @click="det_id=null">close</v-btn>
            </v-card-actions>
            <v-divider class="mx-4"></v-divider>
            <v-card-text class="row justify-space-between">
               <v-col cols="12" md="5" lg="7">
                  <v-row dense justify="space-between">
                     <v-col cols="12" lg="6">
                        <div class="underline">
                           <label class="grey--text">ID</label>
                           <div>{{view.id}}</div>
                        </div>
                        <div class="underline">
                           <label class="grey--text">Waktu</label>
                           <div v-dater="view.created_at" />
                        </div>
                        <div class="underline">
                           <label class="grey--text">Perubahan</label>
                           <div v-dater="view.updated_at" />
                        </div>
                        <div class="underline">
                           <label class="grey--text">Status</label>
                           <v-chip small :class="`stat-${view.status}`">{{all_status[view.status]}}</v-chip>
                        </div>
                     </v-col>
                     <v-col cols="12" lg="5">
                        <div class="underline">
                           <label class="grey--text">Pembeli</label>
                           <div>{{view.name}}</div>
                        </div>
                        <div class="underline">
                           <label class="grey--text">Tlp</label>
                           <div>{{view.phone}}</div>
                        </div>
                        <div class="underline">
                           <label class="grey--text">Alamat</label>
                           <div>{{view.address}}</div>
                        </div>
                        <div class="underline">
                           <label class="grey--text">Catatan</label>
                           <div>{{view.note}}</div>
                        </div>
                     </v-col>
                  </v-row>
               </v-col>
               <v-col cols="12" md="5" lg="4" id="print_area">
                  <div class="text-h6 my-3">Daftar Belanja</div>
                  <div class="list-box">
                     <div class="underline mb-2" v-for="(p,i) in view.product" :key="i">
                        <v-img max-height="40" max-width="40" :src="base_img+p.image || 'https://cdn.vuetifyjs.com/images/cards/cooking.png'"/>
                        <div class="ml-3 mr-auto">
                           <b>{{p.name}}</b>
                           <div class="caption">{{p.quantity}} x {{p.price}}</div>
                        </div>
                        <b v-idr="p.price*p.quantity"/>
                     </div>
                  </div>
                  <div class="d-flex justify-space-between">
                     <b>Total</b>
                     <b v-idr="view.total"/>
                  </div>
                  <div class="d-flex justify-space-between">
                     <div>Bayar</div>
                     <div v-idr="view.cash"/>
                  </div>
                  <div class="d-flex justify-space-between">
                     <div v-if="view.cash_change < 0">Kurang Bayar</div>
                     <div v-else>Kembalian</div>
                     <div v-idr="Math.abs(view.cash_change)"/>
                  </div>
               </v-col>
            </v-card-text>
         </v-card>
      </v-bottom-sheet>
   </v-container>
</template>

<script>
import config from '../config';
import crud from '@/components/crud2';
import api from '@/api';

export default {
   components: { crud },
   data: () => ({
      base_img: config.img_url,
      field: [
         { value: 'id', label: '#', ro: true, tbl: true },
         { value: 'created_at', label: 'Waktu', tbl: true, time: true },
         { value: 'customer_name', label: 'Customer', tbl: true, searchKey: true },
         { value: 'total', label: 'Total', tbl: true },
         { value: 'discount', label: 'Diskon', tbl: true },
         { value: 'cash', label: 'Bayar', tbl: true },
         { value: 'status', label: 'Status', tbl: true, filter: true, opts: [{value: 1, text:'Selesai'}, {value: 2, text:'Pending'}, {value: 3, text:'Batal'}]},
         { value: 'act', label: 'Aksi', ro: true, tbl: true, dis_sort: true }
      ],
      all_status: {1: 'Selesai',2:'Pending',3:'Batal'},
      det_id: null,
      view: {},
      load_detail: false,
      customers: [],
      load_cust: false,
      search_cust: null
   }),
   watch: {
      det_id(val) {
         if(!val) return;
         this.load_detail = true;
         api.get(`transaction/${val}`).then(rsl => {
            if(rsl.data) this.view = rsl.data;
            else {
               this.$alert('Ooops!', rsl.msg, 'notification');
               this.view = [];
            }
         }).finally(() => { this.load_detail = false });
      },
      search_cust(val) {
         clearTimeout(this.event_timer);
         this.event_timer = setTimeout(() => {
            this.load_cust = true;
            api.get('cust', {name: val}).then(rsl => {
               this.customers = rsl.data.map(x => ({value: x.id, text: x.name}));
            }).finally(() => { this.load_cust = false });
         }, 1000);
      }
   },
   methods: {
      filterEvent(val) {
         this.field[2].filter_value = val;
         this.$refs.crud.getData();
      },
      print() {
         api.get(`transaction/${this.det_id}/print`).then(rsl => {
            if(rsl.status === 1) {
               this.$alert({title:'Berhasil', text:rsl.msg, type:'notification', timer:2000});
            } else this.$alert('Ooops!', rsl.msg, 'error');
         });
      },
      process() {
         this.$store.commit('set', {transaction: this.view});
         this.det_id = null;
      },
      cancel() {
         this.$alert({
            title: 'Perhatian!',
            text: 'batalkan transaksi ini?',
            tipe: 'question',
            ok: () => {
               api.edit('transaction',this.view.id, {status: 3}).then(rsl => {
                  if(rsl.status !== 1) return this.$alert('Ooops!', rsl.msg, 'error');
                  this.$refs.crud.getData();
                  this.det_id = null;
               });
            },
            cancel: true
         });
      },
   }
}
</script>
<style scoped>
.list-box {max-height: 200px;overflow: auto;}
.underline {display: flex;justify-content: space-between;align-items: center;min-height: 36px;border-bottom: 1px solid #ddd;}
</style>